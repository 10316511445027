import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';
import { TeamWrapper, TeamMembersWrapper } from './section.style';
import TeamMember from './team-member';

const TeamArea = ({ sectionTitleStyle }) => {
  const data = useStaticQuery(graphql`
    query ventureCapitalTeamQuery {
      images: ventureCapitalJson(id: { eq: "venture-capital-team" }) {
        teamMembers {
          image {
            childImageSharp {
              fixed(width: 90, height: 90, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          name
        }
      }
      content: ventureCapitalTeamJson {
        title
        teamMembers {
          id
          name
          role
          bio
          linkedIn
        }
      }
    }
  `);
  const { title, teamMembers } = data.content;

  const images = data.images.teamMembers;

  // match images from JSON to content from JSON using name as a key:
  teamMembers.forEach((member) => {
    const match = images.find(
      (imageData) => imageData.name.toLowerCase() === member.name.toLowerCase(),
    );
    if (match) {
      member.image = match.image;
    }
    return member;
  });

  return (
    <TeamWrapper className="top-black-grey-gradient">
      <Container>
        <Row>
          <Col lg={12} align="left">
            <SectionTitle {...sectionTitleStyle} title={title} />
          </Col>
        </Row>
        <TeamMembersWrapper>
          {teamMembers &&
            teamMembers.map((teamMember) => (
              <TeamMember key={`teamMember-${teamMember.id}`} {...teamMember} />
            ))}
        </TeamMembersWrapper>
      </Container>
    </TeamWrapper>
  );
};

TeamArea.propTypes = {
  sectionTitleStyle: PropTypes.object,
};

TeamArea.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    color: 'white',
    align: 'left',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
};

export default TeamArea;
