import styled from 'styled-components';

export const TeamMemberWrapper = styled.article`
  display: grid;
  grid-template-rows: min-content 80px 1fr;
  grid-row-gap: 20px;
`;

export const TeamMemberImageWrapper = styled.div`
  img {
    border-radius: 50%;
  }
`;

export const TeamMemberTitleWrapper = styled.div``;

export const TeamMemberBioWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
`;
