import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';
import { SectionWrapper } from './activity-area.style';
import { FixedObject, FluidObject } from 'gatsby-image';
import ActivityItem from '../../../components/activity-item';

interface ActivityAreaProps {
  sectionTitleStyle: object;
}

type ChildImage = {
  childImageSharp: {
    fluid?: FluidObject;
    fixed?: FixedObject;
  };
};

interface Section {
  id: string;
  title: string;
  body: string;
  sources: ChildImage[];
  link: string;
  buttonTitle: string;
}

const ActivityArea = ({ sectionTitleStyle }: ActivityAreaProps) => {
  const data = useStaticQuery(graphql`
    query ventureCapitalActivityQueryData {
      images: ventureCapitalJson(id: { eq: "venture-capital-activity" }) {
        sections {
          title
          image: image {
            childImageSharp {
              fixed(width: 300, height: 75, quality: 100, fit: INSIDE) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
      content: ventureCapitalActivityJson {
        header {
          title
          subtitle
        }
        sections {
          id
          title
          body
          link
          buttonTitle
        }
      }
    }
  `);

  const { header, sections } = data.content;

  const images = data.images.sections;

  const sectionsWithImages: (Section & { image: ChildImage })[] = sections.map((section: Section) => {
    const match = images.find(
      (imageData: Section) =>
        imageData.title.toLowerCase() === section.title.toLowerCase(),
    );
    if (match) {
      (section as Section & { image: ChildImage }).image = match.image;
    }
    return section;
  });

  return (
    <SectionWrapper>
      <Container>
        <Row>
          <Col lg={12}>
            {header && (
              <SectionTitle
                {...sectionTitleStyle}
                title={header.title}
                subtitle={header.subtitle}
              />
            )}
          </Col>
        </Row>
        <Row>
          {sectionsWithImages &&
            sectionsWithImages.map((section: Section & { image: ChildImage }, i: number) => (
              <Col lg={6} key={`cvc-section-${section.id}`}>
                <ActivityItem {...section} />
              </Col>
            ))}
        </Row>
      </Container>
    </SectionWrapper>
  );
};

ActivityArea.defaultProps = {
  sectionTitleStyle: {
    mb: '40px',
    align: 'center',
    responsive: {
      small: {
        mb: '30px',
      },
    },
  },
};

export default ActivityArea;
