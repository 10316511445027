import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionRightImage from 'components/section-right-image';
import { SectionWrap } from './text-area.style';

const TextArea = () => {
  const data = useStaticQuery(graphql`
    query corporateVentureCapitalTextAreaQuery {
      json: ventureCapitalJson(id: { eq: "venture-capital-cdo" }) {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      content: ventureCapitalTextAreaJson {
        cdo {
          header {
            title
            subtitle
          }
          body
        }
      }
    }
  `);

  if (!data.content?.cdo) {
    return null;
  }

  const { cdo } = data.content;

  return (
    <SectionWrap>
      <SectionRightImage
        contents={{
          ...cdo.header,
          ...cdo,
        }}
        className="cvc-text-area-section"
        image={data.json.image}
      />
    </SectionWrap>
  );
};

export default TextArea;
