import React from 'react';
import SectionTextContent from 'components/section-text-content';

const CTAArea = () => {
  const data = null;

  if (!data) {
    return null;
  }

  const { cta } = data;

  return <SectionTextContent className="left-red-slice" {...cta} />;
};

export default CTAArea;
