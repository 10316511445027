import styled from 'styled-components';
import { device } from 'theme';

export const SectionWrap = styled.div`
  @media ${device.medium} {
    .cvc-text-area-section {
      .col-image-container {
        display: none;
      }
    }
  }
`;
