import styled from 'styled-components';
import { device } from 'theme';

export const ActivityItemWrapper = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin: 30px 0;
  align-items: center;
  grid-template-rows: min-content;

  @media ${device.small} {
    margin: 60px 0;
  }
`;

export const ActivityItemInfo = styled.div``;
