import styled from 'styled-components';
import { device } from 'theme';

export const SectionWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 70px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 10px;
  }
`;

export const FeaturedWrapper = styled.div`
  @media ${device.small} {
    margin-bottom: 50px;
  }
`;
