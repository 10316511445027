import React from 'react';
import SEO from 'components/seo';
import Hero from 'components/hero';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import TextArea from 'containers/venture-capital/text-area';
import ActivityArea from 'containers/venture-capital/activity-area';
import TeamArea from 'containers/venture-capital/team';
import { graphql, useStaticQuery } from 'gatsby';
import CTAArea from 'containers/venture-capital/cta-area';
import bgVentureCapital from 'assets/images/bg/headers/CVC-Header.png';

const IndexInfoTechno = ({ location }) => {
  const { ventureCapitalHeroJson: data } = useStaticQuery(graphql`
    query ventureCapitalHeroDataQuery {
      ventureCapitalHeroJson {
        hero {
          title
        }
      }
    }
  `);

  return (
    <Layout location={location}>
      <SEO title="Corporate Venture Capital" image={bgVentureCapital} />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero
          title={data?.hero?.title}
          button={data?.hero?.button}
          hero="venture-capital"
        />
        <TextArea />
        <ActivityArea />
        <TeamArea />
        <CTAArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexInfoTechno;
