import styled from 'styled-components';
import { device } from 'theme';

export const TeamWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const TeamMembersWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @media ${device.small} {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
`;
