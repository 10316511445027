import React, { FC } from 'react';
import Image from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import Button from 'components/ui/button';
import { ActivityItemInfo, ActivityItemWrapper } from './activity-item.style';
import Heading from 'components/ui/heading';

interface Props {
  title: string;
  body: string;
  link: string;
  buttonTitle: string;
  image: any;
  headingStyle?: object;
}

const ActivityItem: FC<Props> = ({
  title,
  image,
  body,
  link,
  buttonTitle,
  headingStyle,
}) => {
  return (
    <ActivityItemWrapper>
      {image?.childImageSharp?.fixed && (
        <Image fixed={image.childImageSharp.fixed} alt={title} />
      )}
      <ActivityItemInfo>
        {title && <Heading {...headingStyle}>{title}</Heading>}
        {body && <ReactMarkdown className="markdownWrapper" source={body} />}
        {link && (
          <Button to={link} varient="texted">
            {buttonTitle}
          </Button>
        )}
      </ActivityItemInfo>
    </ActivityItemWrapper>
  );
};

ActivityItem.defaultProps = {
  headingStyle: {
    as: 'h6',
  },
};

export default ActivityItem;
