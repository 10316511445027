import Button, { ButtonStyles } from 'components/ui/button';
import Heading from 'components/ui/heading';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  TeamMemberWrapper,
  TeamMemberTitleWrapper,
  TeamMemberBioWrapper,
  TeamMemberImageWrapper,
} from './team-member.style';
import Image from 'gatsby-image';
import { StyledComponentsBaseStylingProps } from 'theme';

interface TeamMemberProps {
  name: string;
  image: any;
  role: string;
  bio: string;
  linkedIn: string;
  nameStyle: StyledComponentsBaseStylingProps;
  roleStyle: StyledComponentsBaseStylingProps;
  buttonStyles: ButtonStyles;
}

const TeamMember: FC<TeamMemberProps> = ({
  name,
  image,
  role,
  bio,
  linkedIn,
  nameStyle,
  roleStyle,
  buttonStyles,
}) => (
  <TeamMemberWrapper>
    {image && (
      <TeamMemberImageWrapper>
        <Image fixed={image.childImageSharp.fixed} />
      </TeamMemberImageWrapper>
    )}
    <TeamMemberTitleWrapper>
      <Heading {...nameStyle}>{name}</Heading>
      <Heading {...roleStyle}>{role}</Heading>
    </TeamMemberTitleWrapper>
    <TeamMemberBioWrapper>
      <ReactMarkdown>{bio}</ReactMarkdown>
      <Button {...buttonStyles} to={linkedIn} varient="texted">
        LinkedIn
      </Button>
    </TeamMemberBioWrapper>
  </TeamMemberWrapper>
);

TeamMember.defaultProps = {
  nameStyle: {
    as: 'h3',
    fontSize: '24px',
    mb: '5px',
    color: 'white',
  },
  roleStyle: {
    as: 'h6',
    color: 'white',
  },
  buttonStyles: {
    color: '#0072F0',
  },
};

export default TeamMember;
